import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { BadgeModel } from '../../models/badge.model';

export default function BadgeCard(props: { badge: BadgeModel }) {
  const { badge } = props;
  console.log(badge);

  return (
    <Card sx={{ maxWidth: 600, margin: '5px' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="300"
          image={badge.badgeUrl}
          alt="badge image"
          style={
            {
              backgroundColor: '#fff',
              padding: 5,
              width: '80%',
              height: '80%',
              margin: 'auto',
            }
          }
        />
        <CardContent>
          <Typography
            style={{
              height: 60,
            }}
            gutterBottom variant="h5" component="div">
            {badge.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {badge.description}
          </Typography>
          <Typography style={{
            marginTop: '10px',
          }} variant="caption" color="text.secondary">
            {badge.expiration} anos de validade
          </Typography>
          <Typography
            style={{
              fontWeight: 'bold',
            }}
           variant="subtitle1" color="text.secondary">
            {badge.code}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
