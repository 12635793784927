import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import { ProfileModel } from '../../models/profile.model';

export const AccountProfile = (props: { 
  profile: ProfileModel,
  uploadAvatar: any,
 }) => (
  <Card>
    <CardContent>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Avatar
          src={props.profile.avatarUrl}
          sx={{
            height: 80,
            mb: 2,
            width: 80
          }}
        />
        <Typography
          gutterBottom
          variant="h5"
        >
          {props.profile.name}
        </Typography>
      </Box>
    </CardContent>
    <Divider />
    <CardActions>
      {/* <Button
        fullWidth
        variant="text"
      >
        Upload picture
      </Button> */}
    </CardActions>
  </Card>
);