import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  InputAdornment
} from '@mui/material';
import { ProfileModel } from '../../models/profile.model';

export const AccountProfileDetails = (props: { 
  profile: ProfileModel,
  profileEdit: ProfileModel,
  changeProfileEdit: any,
  saveProfileEdit: any,
 }) => {
  const {
    changeProfileEdit,
    profile,
    profileEdit,
    saveProfileEdit,
  } = props;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeProfileEdit({
      [event.target.name]: event.target.value,
    })
  }

  const onSubmit = () => {
    saveProfileEdit();
  }

  const onCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  return (
    <form
      autoComplete="off"
      noValidate
      onSubmit={onCancel}
    >
      <Card>
        <CardHeader
          subheader=""
          title="Informações Adicionais"
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid
              container
              spacing={3}
            >
              <Grid
                xs={12}
                md={12}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Nome"
                  name="name"
                  required
                  value={profileEdit.name}
                  onChange={onChange}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Email"
                  name="email"
                  disabled
                  required
                  value={profile.email}
                />
              </Grid>
              <Grid
                xs={12}
                md={6}
              >
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Telefone"
                  name="phone"
                  value={profileEdit.phone}
                  onChange={onChange}
                />
              </Grid>
              <Grid
                xs={12}
                md={12}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Linkedin"
                  name="linkedin"
                  value={profileEdit.linkedin}
                  onChange={onChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">https://www.linkedin.com/in/</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            onClick={onSubmit}
          >
            Atualizar
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};