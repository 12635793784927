import { Dispatch, bindActionCreators } from "redux";
import { LOAD_DATA } from "../../../../redux/actions";

export const loadBadges = () => {
  return ({
    type: LOAD_DATA,
    meta: {
      entity: 'badges',
      url: '/badges',
    }
  })
};

export const badgesActions = (dispatch: Dispatch) => bindActionCreators({
  loadBadges,
}, dispatch);