import { Button, Typography } from '@mui/material';
import CredentialModel from '../models/credential.model';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { blue } from '@mui/material/colors';

export default function LinkedinShareButton(props: { credential: CredentialModel }) {
  const { 
    credential: {
      name, 
      organizationId,
      issueYear,
      issueMonth,
      expirationYear,
      expirationMonth,
      certUrl,
      certId,
    }
  } = props;
  const startTask = 'startTask=CERTIFICATION_NAME';
  // const skills = 'Product%20Management,Product%20Engineering,Product%20Operations,Product%20Delivery,Product%20Observability';
  const shareIn = () => {
    const url = `https://www.linkedin.com/profile/add?${startTask}&name=${name}&organizationId=${organizationId}&issueYear=${issueYear}&
issueMonth=${issueMonth}&expirationYear=${expirationYear}&expirationMonth=${expirationMonth}&certUrl=${certUrl}&certId=${certId}`;
    window.open(url, "_blank");
  }
  const color = blue[500];
  
  return (
    <Button onClick={shareIn} size="large" sx={{
      color: color
    }} >
      <LinkedInIcon fontSize="large" />
      <Typography>
        Adicionar
      </Typography>
    </Button>
  );
}
