import { takeLatest, getContext, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { Axios } from 'axios';
import { PROFILE_AVATAR_UPLOAD, PROFILE_AVATAR_UPLOAD_SUCCESS } from '../actions/profile.actions';
import { AnyAction } from 'redux';
import { FAILURE } from '../../../../redux/actions';

async function pushOrderData(data: any, axios: Axios) {
    const formData = new FormData();
    formData.append("file", data.file);

    const response = await axios.post(
        '/profiles/photo', 
        formData, 
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    return response;
}

export function* fetchDataSaga(pushOrderDataFn: any, action: AnyAction): SagaIterator {
    try {
        const axios: Axios = (yield getContext('axios')) as Axios;

        const fileUpload = yield call(pushOrderDataFn, action.payload, axios);
        yield put({
          type: PROFILE_AVATAR_UPLOAD_SUCCESS,
          payload: {
            fileUpload,
          }
        });
        console.log('ACTION', action);
    } catch (error) {
        console.log('****** error', error)
        yield put({
            type: FAILURE,
            payload: error,
          });
    }
}

export function* uploadSaga(): SagaIterator {
    yield takeLatest(PROFILE_AVATAR_UPLOAD, fetchDataSaga, pushOrderData);
}