import { takeLatest, getContext, call, put, select } from 'redux-saga/effects';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { Axios } from 'axios';

import { GlobalState } from '../state';
import { AuthModel } from '../../models/auth.model';
import { FAILURE, LOAD_DATA, LOAD_DATA_SUCCESS } from '../actions';

export function authSelector(state: GlobalState): AuthModel {
    return state.auth;
}

type DataResponse = {
    data?: any,
};

type PullDataArgs = {
  token: string;
  url: string;
}

type PullDataFn = (data: PullDataArgs, axios: Axios) => Promise<DataResponse>;

async function pullData(data: PullDataArgs, axios: Axios): Promise<DataResponse> {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (data.token) {
    headers['Authorization'] = `Bearer ${data.token}`;
  }
  return await axios.get(data.url, { headers });
}

export function* fetchDataSaga(pullDataFn: PullDataFn, action: AnyAction): SagaIterator {
    try {
      const axios: Axios = (yield getContext('axios')) as Axios;
      const auth: AuthModel = (yield select(authSelector)) as AuthModel;
        const pullDataArgs: PullDataArgs = {
          token: auth.accessToken,
          url: action.meta.url,
        }
        console.log('chamou');
        const { data } = yield call(pullDataFn, pullDataArgs, axios);
        yield put({
          type: LOAD_DATA_SUCCESS,
          payload: {
            [action.meta.entity]: data,
          },
          meta: {
            ...action.meta,
            ga: {
              event_category: action.meta.entity,
              event_label: `Listagem de ${action.meta.entity}`,
              value: data,
              type: LOAD_DATA_SUCCESS,
            }
          },
        });

    } catch (error: unknown) {
        console.log('****** error LOAD_PROFILE', error);
        yield put({
          type: FAILURE,
          payload: error,
        });
    }
}

export function* LoadDataSaga(): SagaIterator {
    yield takeLatest(LOAD_DATA, fetchDataSaga, pullData);
}