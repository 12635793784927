import { SagaIterator } from "redux-saga";
import { fork } from "redux-saga/effects";
import { LoadProfileSaga } from "./load.profile.saga";
import { LoadDataSaga } from "./load.data.saga";
import { authLogoutSaga } from "./auth.logout";
import { newRelicSaga } from "./newrelic";
import { gtagSaga } from "./gtag.saga";

export function* rootSaga(): SagaIterator {
  yield fork(LoadProfileSaga);
  yield fork(LoadDataSaga);
  yield fork(authLogoutSaga);
  yield fork(newRelicSaga);
  yield fork(gtagSaga);
}