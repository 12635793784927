import { Link as RouterLink } from 'react-router-dom';
import { Avatar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import { LinkedIn as LinkedInIcon, Print } from '@mui/icons-material'
import { ProfileModel } from "../../models/profile.model";
import CredentialModel from "../../models/credential.model";
import LinkedinShareButton from "../../components/LinkedinShareButton";

type CretentialToolbarProps = {
    credential: CredentialModel,
    profile: ProfileModel,
}

function CredentialToolbar(props: CretentialToolbarProps) {
    const { credential, profile } = props;
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Toolbar sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}>
                <Avatar 
                    sx={{ 
                      width: 56,
                      height: 56,
                      marginRight: (theme) => theme.spacing(2),
                    }}
                    alt={profile.name} 
                    src={profile.avatarUrl}
                />
                <Box sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  maxWidth: 600,
                }}>
                    <Typography sx={{
                      fontSize: 18,
                    }} variant="h6">{profile.name}</Typography>
                    <div>
                      <IconButton
                        component="a"
                        href={`https://linkedin.com/in/${profile.linkedin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                      >
                        <LinkedInIcon />
                      </IconButton>
                      <IconButton
                        component={RouterLink} 
                        to={`/pdf/${credential.certId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                      >
                        <Print />
                      </IconButton>
                    </div>
                </Box>
                <LinkedinShareButton credential={credential} />
            </Toolbar>
        </Box>
    );
}

export default CredentialToolbar;