import { Box, Container, Paper } from '@mui/material';
import CertificateProps from './CertificateProps';

function CredentialDetails(props: CertificateProps) {
  const { 
    credential,
  } = props;

  return (
    <Box sx={{
      padding: 0,
      display: 'flex',
      // width: '100%',
      width: () => ({
        sm: '100%',
        xs: '380px',
        // md: '320px',
        // lg: '320px',
      }),
      height: () => ({
        sm: '320px',
        xs: '320px',
        md: '320px',
        lg: '320px',
      }),
    }}>
      <Container>
        <Paper sx={{
          padding: () => ({
            sm: '10px',
            xs: '10px',
            md: '10px',
            lg: '10px',
          }),
          width: '100%',
          height: () => ({
            sm: '250px',
            xs: '250px',
            md: '250px',
            lg: '250px',
          }),
        }} elevation={0}>
          {credential.description}
        </Paper>
      </Container>
    </Box>
  );
}

export default CredentialDetails;
