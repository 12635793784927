import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CredentialModel from "../../models/credential.model";
import LoadingComponent from "../../components/LoadingComponent";
import { GlobalState } from "../../redux/state";
import CredentialScreen from "./Credential";
import { credentialActions } from "./redux/actions/credential.actions";
import { useParams } from "react-router-dom";

interface DataState extends GlobalState {
  credential: CredentialModel
}

const dataSelector = (state: DataState) => (state.credential)

function Container() {
  const dispatch = useDispatch();
  const { certId } = useParams();
  const actions = credentialActions(dispatch);
  const credential: CredentialModel = useSelector(dataSelector);
  const isLoading = !!(credential && !credential?.id) as boolean;
  
  const screen = credential?.id? 
    <CredentialScreen credential={credential} />: null;

  useEffect(function registerSaga() {
    actions.loadCredential(certId as string);
  }, []);
  return (
    <div>
      {screen}
      <LoadingComponent isLoading={isLoading} />
    </div>
  )
}

export default Container;