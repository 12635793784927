import { Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SideMenu from './SideMenu';
import { LOAD_PROFILE, profileSelector } from '../redux/actions';
import SideMenuMobile from './SideMenuMobile';
import { ProfileModel } from '../models/profile.model';
import { useEffect } from 'react';

function LayoutBase() {
  const dispatch = useDispatch();
  const location = useLocation();
  const profile: ProfileModel = useSelector(profileSelector);
  const isMobile = useMediaQuery('(max-width:600px)');
  const sideMenu = isMobile? < SideMenuMobile />: <SideMenu />;
  useEffect(function registerSaga() {
    dispatch({
      type: '@@route_navigation', payload: location,
    });
    if (profile?.id) {
      dispatch({ type: LOAD_PROFILE });
    }
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexGrow: 1,
    }}>
        {sideMenu}
        <Box component="main" sx={{
          flexGrow: 1,
          padding: 0,
          width: () => ({
            sm: '360px',
          }),
          marginBottom: () => ({
            sm: '80px',
          })
        }}>
            <Outlet />
        </Box>
    </Box>
  )
}

export default LayoutBase;