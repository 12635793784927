import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BadgeModel } from "../../models/badge.model";
import LoadingComponent from "../../components/LoadingComponent";
// import { credentialsActions } from "./redux/actions/credentials.actions";
import { GlobalState } from "../../redux/state";
import { badgesActions } from "./redux/actions/badges.actions";
import BadgesGrid from "./BadgesGrid";

interface DataState extends GlobalState {
  badges: BadgeModel[],
}

const dataSelector = (state: DataState) => (state.badges)

function Container() {
  const dispatch = useDispatch();
  const actions = badgesActions(dispatch);
  const badges: BadgeModel[] = useSelector(dataSelector);
  // const isLoading = !credentials.length;
  const isLoading = !badges?.length as boolean;  
  useEffect(function registerSaga() {
    actions.loadBadges();
      // actions.loadCredentials();
  }, []);

  const grid = badges?.length ?
    <BadgesGrid badges={badges} />: null;

  return (
    <div>
      {grid}
      <LoadingComponent isLoading={isLoading} />
    </div>
  )
}

export default Container;