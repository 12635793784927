import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from "react-redux";
import { profileSelector } from '../redux/actions';
import mainLogo from '../assets/prodops-university-1.png';
import VerifiedIcon from '@mui/icons-material/Verified';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginLogoutListItem from "./LoginLogoutListItem";
import { ProfileModel } from "../models/profile.model";

export default function SideMenuMobile() {
    const profile: ProfileModel = useSelector(profileSelector);

    return (
        <Drawer
            color='secondary'
            sx={{
                // width: '100px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    // width: '100px',
                    boxSizing: 'border-box',
                },
                display: 'flex',
                flexDirection: 'column',
            }}
            variant="permanent"
            anchor="bottom"
        >
            <List sx={{
              display: 'flex',
              flexDirection: 'row',
            }}>
                <ListItem sx={{
                  paddingRight: 0,
                  paddingLeft:0,
                }} key={"logo"} >
                    <ListItemButton component={RouterLink} to="/" sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 35,
                        }}>
                            <img src={mainLogo} width={60} color="white" />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>

                <ListItem sx={{
                  paddingRight: 0,
                  paddingBottom: 0,
                }} key={"credentials"} >
                    <ListItemButton disabled={!profile.id} component={RouterLink} to="/credentials" sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 16,
                        }}>
                            <VerifiedIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText style={{
                            fontSize: '11px',
                        }} primary={"Badges"} />
                    </ListItemButton>
                </ListItem>

                <ListItem sx={{
                  paddingRight: 0,
                  paddingBottom: 0,
                }} key={"profile"} >
                    <ListItemButton disabled={!profile.id} component={RouterLink} to="/profile" sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 16,
                        }}>
                            <AccountCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={"Perfil"} />
                    </ListItemButton>
                </ListItem>

                <LoginLogoutListItem profile={profile} />
            </List>

        </Drawer>
    )
}
