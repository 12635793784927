import { GlobalAction } from "../../../../redux/actions";
import { GlobalState } from "../../../../redux/state";
import { AUTH_LOGIN_FORM_CHANGE, AUTH_LOGIN_FORM_SUBMIT, AUTH_LOGIN_FORM_SUBMIT_FAILURE, AUTH_LOGIN_FORM_SUBMIT_SUCCESS, AUTH_LOGOUT_CLEAR, AUTH_RECOVERY } from "../actions/auth.action";

function reducer(state: GlobalState, action: GlobalAction) {
    
    if (action.type === AUTH_RECOVERY) {
      return {
        ...state,
        auth: {
          ...state.auth,
          infos: 'Link para recuperar sua senha foi enviada para sua caixa de email',
        }
      }
    }

    if (action.type === AUTH_LOGIN_FORM_CHANGE) {
      return {
          ...state,
          auth: {
            ...state.auth,
            ...action.payload,
          }
      }
    }

    if (action.type === AUTH_LOGIN_FORM_SUBMIT) {
      const errors = {
        email: {
          value: 'Este campo não pode ser vazio',
          invalid: !state.auth.email,
        },
        password: {
          value: 'Este campo não pode ser vazio',
          invalid: !state.auth.password,
        },
      }

      return {
        ...state,
        auth: {
          ...state.auth,
          errors: {
            ...state.auth.errors,
            ...errors,
          }
        }
      }
    }

    if (action.type === AUTH_LOGIN_FORM_SUBMIT_FAILURE) {
      console.log('AUTH_LOGIN_FORM_SUBMIT_FAILURE', state.auth)
      return {
        ...state,
        auth: {
          ...state.auth,
          errors: {
            ...state.auth.errors,
            invalid: true,
            remote: action.payload, 
          }
        }
      }
    }

    if (action.type === AUTH_LOGIN_FORM_SUBMIT_SUCCESS) {
      console.log('AUTH_LOGIN_FORM_SUBMIT_SUCCESS', state.auth)
      const errors = {
        email: {
          value: '',
          invalid: false,
        },
        password: {
          value: '',
          invalid: false,
        },
      }

      return {
        ...state,
        auth: {
          ...state.auth,
          ...action.payload,
          infos: '',
          email: '',
          password: '',
          errors,
        }
      }
    }

    if (action.type === AUTH_LOGOUT_CLEAR) {
      const errors = {
        email: {
          value: '',
          invalid: false,
        },
        password: {
          value: '',
          invalid: false,
        },
      }

      return {
        ...state,
        profile: {},
        profileEdit: {},
        auth: {
          email: '',
          password: '',
          errors,
          accessToken: '',
          infos: state.auth.infos,
        }
      }
    }

    return state;
}

export default reducer;