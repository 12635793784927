import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CredentialModel from "../../models/credential.model";
import CredentialsGrid from "./CredentialsGrid";
import LoadingComponent from "../../components/LoadingComponent";
import { credentialsActions } from "./redux/actions/credentials.actions";
import { GlobalState } from "../../redux/state";
import { ProfileModel } from "../../models/profile.model";
import { profileSelector } from "../../redux/actions";

interface DataState extends GlobalState {
  credentials: CredentialModel[]
}

const dataSelector = (state: DataState) => (state.credentials)

function Container() {
  const dispatch = useDispatch();
  const profile: ProfileModel = useSelector(profileSelector);
  const actions = credentialsActions(dispatch);
  const credentials: CredentialModel[] = useSelector(dataSelector);
  // const isLoading = !credentials.length;
  const isLoading = !credentials?.length as boolean;  
  useEffect(function registerSaga() {
    if (profile?.id) {
      actions.loadCredentials();
    }
  }, []);

  const grid = credentials?.length ?
    <CredentialsGrid credentials={credentials} />: null;

  return (
    <div>
      {grid}
      <LoadingComponent isLoading={isLoading} />
    </div>
  )
}

export default Container;