import { Dispatch, bindActionCreators } from "redux";
import { ProfileModel } from "../../../../models/profile.model";
import profileEditReducer from "../reducers/profile.edit.reducer";

export const PROFILE_AVATAR_UPLOAD = '@@PROFILE_AVATAR_UPLOAD';
export const PROFILE_AVATAR_UPLOAD_SUCCESS = '@@PROFILE_AVATAR_UPLOAD_SUCCESS';

export const CHANGE_PROFILE_EDIT = '@@CHANGE_PROFILE_EDIT';
export const SAVE_PROFILE_EDIT = '@@SAVE_PROFILE_EDIT';

export type AvatarFile = {
  file: File,
  name: string,
  contentType: string,
}

export const uploadAvatar = (payload: AvatarFile) => {
  console.log('uploadAvatar', payload);
  return ({
    type: PROFILE_AVATAR_UPLOAD,
    payload,
    meta: {
        //reducer: cartReducer,
    },
  })
};

export const changeProfileEdit = (payload: ProfileModel) => {
  return ({
    type: CHANGE_PROFILE_EDIT,
    payload,
    meta: {
      reducer: profileEditReducer,
    }
  })
}

export const saveProfileEdit = () => {
  return ({
    type: SAVE_PROFILE_EDIT,
  })
}

export const profileActions = (dispatch: Dispatch) => bindActionCreators({
  uploadAvatar,
  changeProfileEdit,
  saveProfileEdit,
}, dispatch);