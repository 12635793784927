import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { BadgeModel } from '../../models/badge.model';
import BadgeCard from './BadgeCard';

export default function BadgesGrid(props: {
  badges: BadgeModel[]
}) {
  const badges: BadgeModel[] = props.badges;
  return (
    <Box sx={{ flexGrow: 1, paddingBottom: 5 }}>
      <Grid container spacing={2}>
        {badges.map((badge: BadgeModel) => (
          <Grid item xs={12} sm={6} md={6} key={badge.id}>
            <BadgeCard badge={badge} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
