import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from "react-redux";
import { profileSelector } from '../redux/actions';
import mainLogo from '../assets/prodops-university-1.png';
import VerifiedIcon from '@mui/icons-material/Verified';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginLogoutListItem from "./LoginLogoutListItem";
import { ProfileModel } from "../models/profile.model";

export default function SideMenu() {
    const profile: ProfileModel = useSelector(profileSelector);

    return (
        <Drawer
            color='secondary'
            sx={{
                width: 100,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: 100,
                    boxSizing: 'border-box',
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <List sx={{
                paddingBottom: 0,
            }}>
                <ListItem key={"logo"} >
                    <ListItemButton component={RouterLink} to="/" sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 35,
                        }}>
                            <img src={mainLogo} width={70} color="white" />
                        </ListItemIcon>
                    </ListItemButton>
                </ListItem>
                <ListItem key={"credentials"} >
                    <ListItemButton disabled={!profile.id} component={RouterLink} to="/credentials" sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 35,
                        }}>
                            <VerifiedIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText style={{
                            fontSize: '11px',
                        }} primary={"Badges"} />
                    </ListItemButton>
                </ListItem>

                <ListItem key={"profile"} >
                    <ListItemButton disabled={!profile.id} component={RouterLink} to="/profile" sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <ListItemIcon sx={{
                            minWidth: 35,
                        }}>
                            <AccountCircleIcon fontSize="large" />
                        </ListItemIcon>
                        <ListItemText primary={"Perfil"} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
            <Divider />
            <List sx={{
                padding: 0,
            }}>                
                <LoginLogoutListItem profile={profile} />
            </List>

        </Drawer>
    )
}
