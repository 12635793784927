import { takeLatest, getContext, call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Router } from '@remix-run/router';
import { Axios } from 'axios';
import { GlobalState } from '../state';
import { AuthModel } from '../../models/auth.model';
import { FAILURE, LOAD_PROFILE, LOAD_PROFILE_SUCCESS } from '../actions';
import profileReducer from '../reducers/profile.reducer';

export function authSelector(state: GlobalState): AuthModel {
    return state.auth;
}

type LoginResponse = {
    access_token: string,
};

async function pullData(data: string, axios: Axios): Promise<LoginResponse> {
    return await axios.get('/auth/profile', {
      headers: {
        'Authorization': `Bearer ${data}`,
      },
    });
}

async function navigateToLogin(router: Router) {
  return router.navigate('/auth/login');
}

export function* fetchDataSaga(pullDataFn: (data: string, axios: Axios) => Promise<LoginResponse>): SagaIterator {
    try {
      const axios: Axios = (yield getContext('axios')) as Axios;
      // const router: Router = (yield getContext('router')) as Router;
      const auth: AuthModel = (yield select(authSelector)) as AuthModel;
      if (auth.accessToken) {
        const { data: profile } = yield call(pullDataFn, auth.accessToken, axios);
        yield put({
          type: LOAD_PROFILE_SUCCESS,
          payload: {
            profile,
            profileEdit: profile,
          },
          meta: {
            reducer: profileReducer, 
          },
        })
      } else {
        // yield call(navigateToLogin, router);
      }
    } catch (error: unknown) {
        console.log('****** error LOAD_PROFILE', error)
        yield put({
          type: FAILURE,
          payload: error,
        });
    }
}

export function* LoadProfileSaga(): SagaIterator {
    yield takeLatest(LOAD_PROFILE, fetchDataSaga, pullData);
}