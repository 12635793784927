import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CustomStore from './redux/CustomStore';

import Layout from './layout/Layout';
import LayoutBase from './layout/LayoutBase';
import CredentialsContainer from './views/credentials/Container';
import CredentialContainer from './views/credential/Container';
import ProfileContainerScreen from './views/profile/Container';
import HomeContainer from './views/home/Container';
import LoginContainerScreen from './views/auth/LoginRouter';
import LogoutContainerScreen from './views/auth/LogoutRouter';
import RecoveryContainerScreen from './views/auth/Recovery';
import ResetContainerScreen from './views/auth/Reset';
import CertificatePDFApp from './views/credential/pdf/certificate.pdf';
import UnloggedLayoutBase from './layout/unlogged/LayoutBase';

const router = createBrowserRouter([
  {
    path: "/",
    Component: LayoutBase,
    children: [
      {
        index: true,
        Component: HomeContainer, 
      },
      {
        path: "credentials",
        Component: CredentialsContainer,
      },
      {
        path: "profile",
        Component: ProfileContainerScreen,
      },
      {
        path: "logout",
        Component: LogoutContainerScreen,
      },
      // {
      //   path: "pdf/:certId",
      //   Component: CertificatePDFApp,
      // },
    ],
  },
  {
    path: "pdf/:certId",
    Component: CertificatePDFApp,
  },
  {
    path: "/public",
    Component: UnloggedLayoutBase,
    children: [
      {
        path: "credentials/:certId",
        Component: CredentialContainer,
      },
    ],
  },
  {
    path: "/auth",
    Component: UnloggedLayoutBase,
    children: [
      {
        path: "login",
        Component: LoginContainerScreen,
      },
      {
        path: "recovery",
        Component: RecoveryContainerScreen,
      },
      {
        path: "password-reset/:token",
        Component: ResetContainerScreen,
      },
    ],
  },
]);

function App(): JSX.Element {
  const store = new CustomStore(router);

  return (
    <Provider store={store}>
      <Layout>
        <RouterProvider router={router} />
      </Layout>
    </Provider>
  );
}

export default App;
