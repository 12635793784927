import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CredentialCard from './CredentialCard';
import CredentialModel from '../../models/credential.model';

export default function CredentialsGrid(props: {
  credentials: CredentialModel[]
}) {
  const credentials: CredentialModel[] = props.credentials;
  return (
    <Box sx={{ flexGrow: 1, paddingBottom: 5 }}>
      <Grid container spacing={2}>
        {credentials.map((credential) => (
          <Grid item xs={12} sm={6} md={6} key={credential.id}>
            <CredentialCard credential={credential} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
