import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import CredentialModel from '../../models/credential.model';

type CertificateSettingsProps = {
  credential: CredentialModel;
}

export default function CertificateSettings(props: CertificateSettingsProps) {
  const { credential } = props;
  return (
    <Box
      sx={{ 
        // width: '100%', 
        bgcolor: 'background.paper',
        paddingLeft: '2px',
        position: 'absolute',
        bottom: (theme) => theme.spacing(2),
        left: (theme) => theme.spacing(2),
        // width: '250px',
        height: 'auto',
        fontSize: 12,
      }}>
      <nav aria-label="date">
        <List>
          <ListItem disablePadding>
            Data: { credential.issueMonth}, {credential.issueYear}
          </ListItem>
          <ListItem disablePadding>
            Expiração: { credential.expirationMonth}, {credential.expirationYear}
          </ListItem>
        </List>
      </nav>
      <Divider />
      <nav aria-label="id">
        <List>
          <ListItem disablePadding>
            ID: { credential.certId }
          </ListItem>
          <ListItem disablePadding>
            Badge Code: { credential.badgeCode }
          </ListItem>
        </List>
      </nav>
    </Box>
  );
}
