const options = {
    init: {
        distributed_tracing: { 
            enabled: true,
            cors_use_newrelic_header:true,
            cors_use_tracecontext_headers:true,
            allowed_origins:[
                'http://localhost:3001',
                'http://localhost:3002',
                'http://localhost:3000',
                'http://localhost:5173',
                'https://prodops.university'
            ],
        },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: ["bam.nr-data.net"] },
    },
    info: {
        beacon: "bam.nr-data.net",
        errorBeacon: "bam.nr-data.net",
        licenseKey: "NRJS-4f007dad3162b1fcbb0",
        applicationID: "594443720",
        sa: 1,
    },
    loader_config: {
        accountID: "4199962",
        trustKey: "4199962",
        agentID: "594443720",
        licenseKey: "NRJS-4f007dad3162b1fcbb0",
        applicationID: "594443720",
    }
};

export default options;
