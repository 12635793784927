import { Container, Paper, Typography, Box } from '@mui/material';
import CertificateProps from './CertificateProps';
import CertificateSettings from './CertificateSettings';

function formatMonth(monthNumber: number) {
  const mes = Number(monthNumber) - 1;
  const date = new Date(2023, (mes), 1);
  const month = date.toLocaleString('pt-BR', { month: 'long' });
  return month.toUpperCase();
}

function Certificate(props: CertificateProps) {
  const { 
    credential,
    credential: {
      name,
      badgeUrl,
      organizationName,
      issueYear,
      issueMonth,
      issuerName,
    }
  } = props;

  return (
    <Box sx={{
      display: 'flex',
      backgroundColor: '#f4f5fa',
      paddingTop: 1,
      paddingBottom: 1,
      height: () => ({
        xs: '250px',
        md: '500px',
        lg: '500px',
        sm: '500px',
      }),
      "@media print": {
        width: "210mm",
        height: "297mm",
      },
    }} id="printableArea">
      <Container sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transform: 
         ({
          xs: 'scale(0.4)',
          md: 'none',
          lg: 'none',
          sm: 'none',
        }),
        // transformOrigin: 
        // () => ({
        //   xs: 'top left',
        //   md: 'top left',
        //   lg: 'top left',
        //   sm: 'top left',
        // }),
      }}>
        <Paper sx={{
          paddingTop: 2,
          width: '100%',
          maxWidth: '842px',
          minWidth: () => ({
            xs: '900px',
            md: '800px',
            lg: '800px',
            sm: '800px',
          }),
          textAlign: 'center',
          position: 'absolute',
          background: '#fff',
          '& *': {
            color: '#000',
          },
        }} elevation={3}>
          <CertificateSettings credential={credential} />
          <img 
            src={badgeUrl} 
            alt="Logo da certificação" 
            style={{
                width: '200px',
                height: 'auto',
            }}
          />
          <Typography variant="h3" gutterBottom>
            {name}
          </Typography>
          <Typography variant="body1" paragraph>
            É concedido a
          </Typography>
          <Typography sx={{
            textTransform: 'uppercase',
          }} variant="h6" gutterBottom>
            {credential.profile?.name}
          </Typography>
          <Typography variant="body1" paragraph>
            por concluir com sucesso os requisitos em
          </Typography>
          <Typography variant="h6" gutterBottom>
          {formatMonth(issueMonth)}, {issueYear} 
          </Typography>
          <Box mt={3}>
            <Typography variant="body2">
              Concedido por
            </Typography>
            <Typography variant="h6" gutterBottom>
              {organizationName}
            </Typography>
          </Box>
          <Box sx={{
            position: 'absolute',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
            width: '250px',
            height: 'auto',
            background: '#fff',
            zIndex: 2,
          }}>
            <img 
              src="https://certificare-assets.s3.amazonaws.com/assinatura.png" 
              style={{
                width: 250,
                position: 'relative',
                bottom: -30,
                zIndex: -1,
              }}
              alt="Assinatura"
               />
            <div style={{
              background: 'transparent',
            }}>__________________________</div>
            <Box>Instrutor: {issuerName}</Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
}

export default Certificate;
