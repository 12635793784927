import { Dispatch, bindActionCreators } from "redux";
import { LOAD_DATA } from "../../../../redux/actions";

export const loadCredential = (certId: string) => {
  return ({
    type: LOAD_DATA,
    meta: {
      entity: 'credential',
      url: `/credentials/${certId}`,
    }
  })
};

export const credentialActions = (dispatch: Dispatch) => bindActionCreators({
  loadCredential,
}, dispatch);