import { Container, Paper, Typography, Box } from '@mui/material';
// import { Text, Document, Page } from '@react-pdf/renderer';
import CertificateProps from '../CertificateProps';
import CertificateSettings from './CertificateSettingsPDF';

function Certificate(props: CertificateProps) {
  const { 
    credential,
    credential: {
      name,
      badgeUrl,
      organizationName,
      issueYear,
      issueMonth,
      issuerName,
    }
  } = props;

  return ( 
      <Container sx={{
        display: 'flex',
        height: '650px',
        "@media print": {
          padding: 'auto',
        },
      }}>
        <Paper sx={{
          height: '650px',
          paddingTop: 2,
          // width: '100%',
          maxWidth: '900px',
          minWidth: '900px',
          textAlign: 'center',
          position: 'absolute',
          background: '#fff',
          '& *': {
            color: '#000',
          },
        }} elevation={3}>
          <CertificateSettings credential={credential} />
          <img 
            src={badgeUrl} 
            alt="Logo da certificação" 
            style={{
                width: '200px',
                height: 'auto',
            }}
          />
          <Typography variant="h3" gutterBottom>
            
              {name}
            
          </Typography>
          <Typography variant="body1" paragraph>
            
            É concedido a
            
          </Typography>
          <Typography sx={{
            textTransform: 'uppercase',
          }} variant="h6" gutterBottom>
            
            {credential.profile?.name}
            
          </Typography>
          <Typography variant="body1" paragraph>
            
              por concluir com sucesso os requisitos em
            
          </Typography>
          <Typography variant="h6" gutterBottom>
            
              {issueMonth}, {issueYear} 
            
          </Typography>
          <Box mt={3}>
            <Typography variant="body2">
              
                Concedido por
              
            </Typography>
            <Typography variant="h6" gutterBottom>
              
                {organizationName}
              
            </Typography>
          </Box>
          <Box sx={{
            position: 'absolute',
            bottom: (theme) => theme.spacing(2),
            right: (theme) => theme.spacing(2),
            width: '250px',
            height: 'auto',
            background: '#fff',
            zIndex: 2,
          }}>
            <img 
              src="https://certificare-assets.s3.amazonaws.com/assinatura.png" 
              style={{
                width: 250,
                position: 'relative',
                bottom: -30,
                zIndex: -1,
              }}
              alt="Assinatura"
               />
            <div style={{
              background: 'transparent',
            }}>
              
              __________________________
              
            </div>
            <Box>
              
                Instrutor: {issuerName}
              
            </Box>
          </Box>
        </Paper>
      </Container>

  );
}

export default Certificate;
