import { takeLatest, put, call, getContext } from 'redux-saga/effects';
import { Router } from '@remix-run/router';
import { SagaIterator } from 'redux-saga';
import { AUTH_LOGOUT, AUTH_LOGOUT_CLEAR } from '../../views/auth/redux/actions/auth.action';
import authReducer from '../../views/auth/redux/reducers/auth.reducer';
import { FAILURE } from '../actions';

async function navigateToLogin(router: Router) {
  return router.navigate('/auth/login');
}

export function* fetchDataSaga(): SagaIterator {
    try {
      const router: Router = (yield getContext('router')) as Router;
      yield put({
        type: AUTH_LOGOUT_CLEAR,
        meta: {
          reducer: authReducer,
        }
      });
      yield call(navigateToLogin, router);
    } catch (error: unknown) {
      yield put({
        type: FAILURE,
        payload: error,
      });
    }
}

export function* authLogoutSaga(): SagaIterator {
    yield takeLatest(AUTH_LOGOUT, fetchDataSaga);
}