import { Alert, Box, Button, Container, List, ListItem, ListItemButton, ListItemText, Paper, TextField, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { AuthModel } from '../../models/auth.model';
import { AuthForm } from './redux/actions/auth.action';

function LoginScreen(props: {
  auth: AuthModel,
  formChange: (payload: AuthForm) => void,
  formSubmit: () => void,
}) {
  const { auth, formChange, formSubmit } = props;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formChange({
      [event.target.name]: event.target.value,
    })
  }

  const onSubmit = () => {
    formSubmit()
  }

  const onCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();
  }

  const infos = auth.infos? 
    <Alert severity="info">{auth.infos}</Alert>: null;

  const serverError = auth.errors.invalid ?
  <Alert severity="error">{auth.errors.remote}</Alert>: null;

  return (
    <Box sx={{ flexGrow: 1 }}>

      <Container component="main" maxWidth="xs">
        <Box sx={{
          marginTop: (theme) => theme.spacing(8),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Paper elevation={3} sx={{
            padding: (theme) => theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <form sx={{
              width: '100%',
              marginTop: (theme) => theme.spacing(1),
            }} onSubmit={onCancel}>
              <TextField
                error={auth.errors.email.invalid}
                helperText={auth.errors.email.invalid ? auth.errors.email.value : ''}
                label="Email"
                name="email"
                type="email"
                autoComplete="email"
                value={auth.email}
                onChange={onChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
              />
              <TextField
                error={auth.errors.password.invalid}
                helperText={auth.errors.password.invalid ? auth.errors.password.value : ''}
                label="Senha"
                name="password"
                type="password"
                autoComplete="current-password"
                value={auth.password}
                onChange={onChange}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
              />
              {infos}
              {serverError}
              <Button
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                fullWidth
                onClick={onSubmit}
              >
                Entrar
              </Button>
            </form>

            <List>
                <ListItem key={"home"} >
                    <ListItemButton component={RouterLink} to="/auth/recovery">
                        <ListItemText primary={"Recuperar Senha"} />
                    </ListItemButton>
                </ListItem>
              </List>
          </Paper>
        </Box>
      </Container>

    </Box>
  );
}

export default LoginScreen;

