import { CircularProgress, Backdrop } from '@mui/material';

type LoadingProps = {
  isLoading: boolean
}

const LoadingComponent = ({ isLoading }: LoadingProps) => {
  return (
    <Backdrop sx={{
      zIndex: (theme) => theme.zIndex.drawer + 1,
      color: '#fff',
      position: 'relative',
    }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingComponent;
