import { useDispatch } from "react-redux";
import { AUTH_LOGOUT } from "./redux/actions/auth.action";
import authReducer from "./redux/reducers/auth.reducer";

function Router() {
  const dispatch = useDispatch();
  dispatch({
    type: AUTH_LOGOUT,
    meta: {
      reducer: authReducer,
    }
  })
  return (
    <div>
      Desconetado com sucesso!
    </div>
  )

}

export default Router;