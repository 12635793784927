import { useEffect } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import CustomStore from "../../redux/CustomStore";
import ProfileScreen from "./Profile2";
import { profileEditSelector, profileSelector } from "../../redux/actions";
import { rootSaga } from "./redux/sagas/rootSaga";
import { ProfileModel } from "../../models/profile.model";
import { profileActions } from "./redux/actions/profile.actions";

function Container() {
  const dispatch = useDispatch();
  
  const profile: ProfileModel = useSelector(profileSelector);
  const profileEdit: ProfileModel = useSelector(profileEditSelector);
  const store = useStore() as CustomStore;
  useEffect(function registerSaga() {
    const task = store.run(rootSaga);
    console.log(task);
    return () => {
      if (task) {
        console.log('entrou no cancelar', task);
        task.cancel();
        console.log('cancelou?', task.isCancelled());
      }
    }
  }, [store]);
  const actions = profileActions(dispatch);
  const { 
    uploadAvatar,
    changeProfileEdit,
    saveProfileEdit,
  } = actions;

  return (
    <ProfileScreen 
      uploadAvatar={uploadAvatar}
      profileEdit={profileEdit}
      profile={profile} 
      changeProfileEdit={changeProfileEdit}
      saveProfileEdit={saveProfileEdit}
    />
  )

}

export default Container;