import { takeLatest, getContext, call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Router } from '@remix-run/router';
import { Axios } from 'axios';
import { AnyAction } from 'redux';
import { AUTH_LOGIN_FORM_SUBMIT, AUTH_LOGIN_FORM_SUBMIT_FAILURE, AUTH_LOGIN_FORM_SUBMIT_SUCCESS } from '../actions/auth.action';
import { GlobalState } from '../../../../redux/state';
import { AuthModel } from '../../../../models/auth.model';
import authReducer from '../reducers/auth.reducer';
import { FAILURE, LOAD_PROFILE } from '../../../../redux/actions';

export function authSelector(state: GlobalState): AuthModel {
    return state.auth;
}

type LoginData = { 
    email: string,
    password: string,
};

type LoginResponse = {
    access_token: string,
};

async function pushOrderData(data: LoginData, axios: Axios): Promise<LoginResponse> {
    return await axios.post('/auth/login ', data);
}

async function navigateToProfile(router: Router) {
  return router.navigate('/profile');
}

export function* fetchDataSaga(pushOrderDataFn: (data: LoginData, axios: Axios) => Promise<LoginResponse>, action: AnyAction): SagaIterator {
    try {
        const axios: Axios = (yield getContext('axios')) as Axios;
        const router: Router = (yield getContext('router')) as Router;

        const auth: AuthModel = (yield select(authSelector)) as AuthModel;
        const { data: { access_token } } = yield call(pushOrderDataFn, {
            email: auth.email,
            password: auth.password,
        }, axios);

        yield put({
          type: AUTH_LOGIN_FORM_SUBMIT_SUCCESS,
          payload: {
            accessToken: access_token,
          },
          meta: {
            reducer: authReducer,
          }
        });
        
        console.log('ACTION', action);
        yield put({
          type: LOAD_PROFILE,
        })
        
        yield call(navigateToProfile, router);

    } catch (error: any) {
        console.log('****** error AUTH_LOGIN_FORM_SUBMIT_FAILURE', error)
        yield put({
            type: AUTH_LOGIN_FORM_SUBMIT_FAILURE,
            payload: error?.response?.data?.message,
            meta: {
              reducer: authReducer,
            }
          });
          yield put({
            type: FAILURE,
            payload: error,
          });
    }
}

export function* LoginSaga(): SagaIterator {
    yield takeLatest(AUTH_LOGIN_FORM_SUBMIT, fetchDataSaga, pushOrderData);
}