import { AUTH_LOGOUT_CLEAR } from "../../views/auth/redux/actions/auth.action";
import { initialGlobalState } from "../state";

export const localStorageMiddleware = ({getState}) => { 
  return (next) => (action) => {
      const result = next(action);
      const state = (action.type === AUTH_LOGOUT_CLEAR)?
        initialGlobalState: getState();
      localStorage.setItem('applicationState', JSON.stringify(state));
      return result;
  };
};