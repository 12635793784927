import { call, takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { FAILURE, GlobalAction } from '../actions';
import { Location } from 'react-router';
import { GaModel } from '../../models/ga.model';
import { debug } from 'console';

declare global {
  interface Window {
    gtag: any;
  }
}

interface ActionForAnalytics extends GlobalAction {
    payload: Location
}

interface ActionForError extends GlobalAction {
    payload: Error,
}

interface ActionForGA extends GlobalAction {
    payload: {
      meta: {
        ga: GaModel,
      }
    },
}

async function trackURL(event: ActionForAnalytics) {
  window.gtag('event', 'page_view', {
    page_title: event.payload.pathname, 
    page_location: window.location.href,
    page_path: event.payload.pathname,
  })
}

async function trackEvent(event: ActionForGA) {
  if (event.payload?.meta?.ga) {
    const ga = event.payload.meta.ga;
    window.gtag('event', ga.type, {
      'event_category': ga.event_category,
      'event_label':ga.event_label,
      'value': ga.value,
    });
  } 

}
async function trackError(event: ActionForError) {
  window.gtag('event', 'exception', {
    'description': event.payload.message,
  });
}

export function* fetchDataSaga(action: GlobalAction): SagaIterator {
    try {
        if(action.type === '@@route_navigation') {
            const actionForAnalytics = action as ActionForAnalytics;
            yield call(trackURL, actionForAnalytics);
        } if (action.type === FAILURE) {
            const actionForError = action as ActionForError;
            yield call(trackError, actionForError);
        } else {
          const actionForGA = action as ActionForGA;
          yield call(trackEvent, actionForGA);
        }
    } catch (error) {
        console.log('****** error', error)
    }
}

export function* gtagSaga(): SagaIterator {
    yield takeEvery('*', fetchDataSaga);
}
