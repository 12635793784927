import { GlobalAction } from "../../../../redux/actions";
import { GlobalState } from "../../../../redux/state";
import { CHANGE_PROFILE_EDIT } from "../actions/profile.actions";

export default function profileEditReducer(state: GlobalState, action: GlobalAction): GlobalState {
  if( action.type === CHANGE_PROFILE_EDIT  ) {
    return { 
      ...state, 
      profileEdit: {
        ...state.profileEdit,
        ...action.payload,
      },
    }
  }
  return state;
}