import { useEffect } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import CustomStore from "../../redux/CustomStore";
import { authSelector } from "../../redux/actions";
import LoginScreen from "./Login";
import { AuthModel } from "../../models/auth.model";
import { authActions } from "./redux/actions/auth.action";
import { rootSaga } from "./redux/sagas/root.saga";

function Container() {
  const dispatch = useDispatch();
  const actions = authActions(dispatch);
  
  // const profile: ProfileModel = useSelector(profileSelector);
  const auth: AuthModel = useSelector(authSelector);

  const store = useStore() as CustomStore;
  useEffect(function registerSaga() {
    const task = store.run(rootSaga);

    return () => {

      if (task) {

        task.cancel();
      }
    }
  }, [store]);

  return (
    <LoginScreen 
      formChange={actions.formChange}
      formSubmit={actions.formSubmit}
      auth={auth}
    />
  )

}

export default Container;