import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, CardActions } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import CredentialModel from '../../models/credential.model';
import LinkedinShareButton from '../../components/LinkedinShareButton';

export default function CredentialCard(props: { credential: CredentialModel }) {
  const { credential } = props;
  console.log(credential);

  return (
    <Card sx={{ maxWidth: 600 }}>
      <CardActionArea component={RouterLink} to={`/public/credentials/${credential.certId}`}>
        <CardMedia
          component="img"
          height="300"
          image={credential.badgeUrl}
          alt="badge image"
          style={
            {
              backgroundColor: '#fff',
              padding: 5,
              width: '80%',
              height: '80%',
              margin: 'auto',
            }
          }
        />
        <CardContent>
          <Typography
            style={{
              height: 60,
            }}
            gutterBottom variant="h5" component="div">
            {credential.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {credential.description}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {credential.issueMonth}, {credential.issueYear}
          </Typography>
          <Typography
            style={{
              fontWeight: 'bold',
            }}
           variant="subtitle1" color="text.secondary">
            {credential.organizationName}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <LinkedinShareButton credential={credential} />
      </CardActions>
    </Card>
  );
}
