import { takeLatest, getContext, call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Axios } from 'axios';
import { SAVE_PROFILE_EDIT } from '../actions/profile.actions';
import { FAILURE, LOAD_PROFILE, authSelector, profileEditSelector, profileSelector } from '../../../../redux/actions';
import { AuthModel } from '../../../../models/auth.model';
import { ProfileModel } from '../../../../models/profile.model';

async function pushOrderData(data: any, axios: Axios) {
    const response = await axios.patch(
        `/profiles/${data.id}`, 
        data.profileEdit, 
        {
          headers: {
            'Authorization': `Bearer ${data.token}`,
          }
        });
    return response;
}

export function* fetchDataSaga(pushDataFn: any): SagaIterator {
    try {
        const axios: Axios = (yield getContext('axios')) as Axios;
        const auth: AuthModel = (yield select(authSelector)) as AuthModel;
        const profile: ProfileModel = (yield select(profileSelector)) as ProfileModel;
        const profileEdit: ProfileModel = (yield select(profileEditSelector)) as ProfileModel;
        yield call(pushDataFn, {
          id: profile.id,
          token: auth.accessToken,
          profileEdit,
        }, axios);
        yield put({ type: LOAD_PROFILE });
    } catch (error) {
        console.log('****** error', error)
        yield put({
          type: FAILURE,
          payload: error,
        });
    }
}

export function* saveProfileSaga(): SagaIterator {
    yield takeLatest(SAVE_PROFILE_EDIT, fetchDataSaga, pushOrderData);
}