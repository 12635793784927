import { Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import SideMenuMobile from '../SideMenuMobile';
import SideMenu from '../SideMenu';

function UnloggedLayoutBase() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const sideMenu = isMobile? < SideMenuMobile />: <SideMenu />;

  return (
      <Box sx={{
        display: 'flex',
        flexGrow: 1,
      }}>
          {sideMenu}
          <Box component="main" sx={{
            flexGrow: 1,
            padding: 0,
            width: () => ({
              sm: '360px',
            }),
            marginBottom: () => ({
              sm: '80px',
            })
          }}>
              <Outlet />
          </Box>
      </Box>
  )
}

export default UnloggedLayoutBase;