import { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
} from '@mui/material';
import { useDispatch, useStore } from 'react-redux';
import { AUTH_RECOVERY } from './redux/actions/auth.action';
import CustomStore from '../../redux/CustomStore';
import { recoveryRootSaga } from './redux/sagas/recovery.saga';
import AuthReducer from './redux/reducers/auth.reducer';

function Recovery() {
  const dispatch = useDispatch();
  const store = useStore() as CustomStore;
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Email para recuperação:', email);
    setMessage('Email de recuperação enviado com sucesso!');
    dispatch({
      type: AUTH_RECOVERY,
      payload: email,
      meta: {
        reducer: AuthReducer,
      }
    })
  };

  
  useEffect(function registerSaga() {
    const task = store.run(recoveryRootSaga);

    return () => {

      if (task) {

        task.cancel();
      }
    }
  }, [store]);

  const messageSubmited = message ?
    <Alert severity="info">{message}</Alert>: null;

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{
        marginTop: (theme) => theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Paper elevation={3} sx={{
          padding: (theme) => theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography component="h1" variant="h5">
            Recuperação de Senha
          </Typography>
          {messageSubmited}
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                margin: (theme) => theme.spacing(3, 0, 2),
              }}
            >
              Enviar Email de Recuperação
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}

export default Recovery;
