import { ProfileModel } from "../models/profile.model";
import { AuthModel } from "../models/auth.model";

export type GlobalState = {
    darkMode: string,
    profile: ProfileModel,
    profileEdit: ProfileModel,
    auth: AuthModel,
}

export const initialGlobalState: GlobalState = {
    darkMode: 'light',
    profile: {},
    profileEdit: {
      linkedin: '',
      name: '',
      phone: '',
    },
    auth: {
        accessToken: '',
        email: '',
        password: '',
        errors: {
            invalid: false,
            remote: '',
            email: {
              value: '',
              invalid: false,
            },
            password: {
              value: '',
              invalid: false,
            },
        },
        infos: '',
    },
    // profile: { 
    //     id: '7', 
    //     name: 'Christiano Martins Milfont de Almeida',
    //     avatarUrl: 'https://avatars.githubusercontent.com/u/74883?v=4',
    //     linkedin: 'https://www.linkedin.com/in/cmilfont',
    // }
  };