import { Dispatch, bindActionCreators } from "redux";
import authReducer from "../reducers/auth.reducer";

export const AUTH_LOGIN_FORM_CHANGE = '@@AUTH_LOGIN_FORM_CHANGE';
export const AUTH_LOGIN_FORM_SUBMIT = '@@AUTH_LOGIN_FORM_SUBMIT';
export const AUTH_LOGIN_FORM_SUBMIT_SUCCESS = '@@AUTH_LOGIN_FORM_SUBMIT_SUCCESS';
export const AUTH_LOGIN_FORM_SUBMIT_FAILURE = '@@AUTH_LOGIN_FORM_SUBMIT_FAILURE';

export const AUTH_LOGOUT = '@@AUTH_LOGOUT';
export const AUTH_LOGOUT_CLEAR = '@@AUTH_LOGOUT_CLEAR';

export const AUTH_RECOVERY = '@@AUTH_LOGOUT';
export const AUTH_RECOVERY_SUCCESS = '@@AUTH_RECOVERY_SUCCESS';

export const AUTH_RESET = '@@AUTH_RESET';

export type AuthForm = {
  [key: string]: string;
}

export const formChange = (payload: AuthForm) => {

  return ({
    type: AUTH_LOGIN_FORM_CHANGE,
    payload,
    meta: {
        reducer: authReducer,
    },
  })
};

export const formSubmit = () => {
  return ({
    type: AUTH_LOGIN_FORM_SUBMIT,
    meta: {
        reducer: authReducer,
    },
  })
};

export const authActions = (dispatch: Dispatch) => bindActionCreators({
  formChange,
  formSubmit,
}, dispatch);