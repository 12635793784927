import { PaletteMode } from "@mui/material";
import { Reducer, Action } from "redux";
import { GlobalState } from './state';

export const THEME_SWITCH = '@@THEME_SWITCH';

export const FAILURE = '@@FAILURE';

export const LOAD_PROFILE = '@@LOAD_PROFILE';
export const LOAD_PROFILE_SUCCESS = '@@LOAD_PROFILE_SUCCESS';

export const LOAD_DATA = '@@LOAD_DATA';
export const LOAD_DATA_SUCCESS = '@@LOAD_DATA_SUCCESS';
export const CLEAR_DATA = '@@CLEAR_DATA';

export type Meta = {
    reducer?: Reducer<GlobalState, GlobalAction>;
    entity?: string;
    url?: string;
};

export interface GlobalAction extends Action {
    type: string;
    payload?: object;
    meta?: Meta;
}

export const darkModeSelector = (state: GlobalState) => (state.darkMode as PaletteMode)

export const profileSelector = (state: GlobalState) => (state.profile)
export const profileEditSelector = (state: GlobalState) => (state.profileEdit)
export const authSelector = (state: GlobalState) => (state.auth)