import { Box } from "@mui/material";
import CredentialToolbar from "./CredentialToolbar";
import CredentialCertificate from "./CredentialCertificate";
import CredentialModel from "../../models/credential.model";
import CredentialDetails from "./CredentialDetails";

function CredentialScreen(props: {
    credential: CredentialModel,
}) {
    const { credential } = props;
    const toolbar = credential?.profile? 
        <CredentialToolbar 
            credential={credential} 
            profile={credential.profile} /> : null;

    const certificate = credential?.name?
        <CredentialCertificate credential={credential} />: null;
    return (
        <Box sx={{ flexGrow: 1, margin: 0 }}>
            {toolbar}
            {certificate}
            <CredentialDetails credential={credential} />
        </Box>
    );
}

export default CredentialScreen;