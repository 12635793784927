import { takeLatest, fork, getContext, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { Axios } from 'axios';
import { AnyAction } from 'redux';
import { AUTH_RECOVERY, AUTH_RECOVERY_SUCCESS } from '../actions/auth.action';
import { FAILURE } from '../../../../redux/actions';


async function pushOrderData(email: string, axios: Axios) {
    return await axios.post('/auth/recovery', { email });
}

export function* fetchDataSaga(pushOrderDataFn, action: AnyAction): SagaIterator {
    try {
        const axios: Axios = (yield getContext('axios')) as Axios;
        const { data } = yield call(pushOrderDataFn, action.payload, axios);

        yield put({
          type: AUTH_RECOVERY_SUCCESS,
          payload: data,
        });
        
    } catch (error: any) {
        console.log('****** error AUTH_RECOVERY_SUCCESS', error)
        yield put({
          type: FAILURE,
          payload: error,
        });
    }
}


export function* recoverySaga(): SagaIterator {
  yield takeLatest(AUTH_RECOVERY, fetchDataSaga, pushOrderData);
}

export function* recoveryRootSaga(): SagaIterator {
  yield fork(recoverySaga);
}