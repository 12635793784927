import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Certificate from './CertificatePDF';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CredentialModel from '../../../models/credential.model';
import { credentialActions } from '../redux/actions/credential.actions';
import { GlobalState } from '../../../redux/state';
import { Button } from '@mui/material';
import LoadingComponent from '../../../components/LoadingComponent';

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <Certificate credential={props.credential} />
    </div>
  );
});

interface DataState extends GlobalState {
  credential: CredentialModel
}

const dataSelector = (state: DataState) => (state.credential)
 
const Example = () => {
  const dispatch = useDispatch();
  const { certId } = useParams();
  const actions = credentialActions(dispatch);
  const credential: CredentialModel = useSelector(dataSelector);

  const isLoading = (!!credential && !credential.id) as boolean;
  useEffect(function registerSaga() {
    actions.loadCredential(certId as string);
  }, []);
  
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const certificate = credential?.id ? 
    <ComponentToPrint 
      credential={credential} 
      ref={componentRef} 
    />: null;

  return (
    <div>
      <Button 
        variant="contained"
        onClick={handlePrint}
        sx={{
          margin: '10px'
        }}
      >
        Imprimir
      </Button>
      {certificate}
      <LoadingComponent isLoading={isLoading} />
    </div>
  );
};

export default Example;