import { takeLatest, fork, getContext, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { Router } from '@remix-run/router';
import { Axios } from 'axios';
import { AnyAction } from 'redux';
import { AUTH_RESET } from '../actions/auth.action';
import { FAILURE } from '../../../../redux/actions';

async function navigateToLogin(router: Router) {
  return router.navigate('/auth/login');
}

type PushDataProps = {
  token: string;
  password: string;
}

async function pushData(data: PushDataProps, axios: Axios) {
    return await axios.post('/auth/reset', data);
}

export function* fetchDataSaga(pushDataFn, action: AnyAction): SagaIterator {
    try {
        const axios: Axios = (yield getContext('axios')) as Axios;
        const router: Router = (yield getContext('router')) as Router;
        yield call(pushDataFn, action.payload, axios);
        yield call(navigateToLogin, router);
        
    } catch (error: any) {
        console.log('****** error AUTH_RESET', error)
        yield put({
          type: FAILURE,
          payload: error,
        });
    }
}


export function* resetSaga(): SagaIterator {
  yield takeLatest(AUTH_RESET, fetchDataSaga, pushData);
}

export function* resetRootSaga(): SagaIterator {
  yield fork(resetSaga);
}