import { GlobalAction, LOAD_PROFILE_SUCCESS } from "../actions";
import { GlobalState } from "../state";

export default function profileReducer(state: GlobalState, action: GlobalAction): GlobalState {

  if( action.type === LOAD_PROFILE_SUCCESS  ) {
    return { 
      ...state, 
      ...action.payload,
    }
  }
    

  return state;

}