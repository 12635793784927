import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import { ProfileModel } from "../models/profile.model";

export default function LoginLogoutListItem(props: { profile: ProfileModel}) {
  const { profile } = props;

    const icon = profile?.id? 
        <LogoutIcon fontSize="small" />: 
        <LoginIcon fontSize="small" />;
    const text: string = profile?.id ? "Sair" : "Entrar";
    const url: string = profile?.id ? "/logout" : "/auth/login";

    return (
      <ListItem sx={{
        paddingBottom: 0,
        // paddingTop: 0,
        // paddingRight: 0,
        alignItems: 'center',
      }} key={"exit"} >
          <ListItemButton component={RouterLink} to={url} sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
          }}>
              <ListItemIcon sx={{
                  minWidth: 16,
              }}>
                  {icon}
              </ListItemIcon>
              <ListItemText primary={text} />
          </ListItemButton>
      </ListItem>
    )
}
