import { Box } from "@mui/material";
import Certificate from "./Certificate";
import CertificateProps from "./CertificateProps";

function CredentialCertificate(props: CertificateProps) {
    const { credential } = props;
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Certificate credential={credential} />
        </Box>
    );
}

export default CredentialCertificate;