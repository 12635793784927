import { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
} from '@mui/material';
import { useDispatch, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AUTH_RESET } from './redux/actions/auth.action';
import CustomStore from '../../redux/CustomStore';
import { resetRootSaga } from './redux/sagas/reset.saga';

function Reset() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const store = useStore() as CustomStore;
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('Senha atualizada com sucesso!');
    dispatch({
      type: AUTH_RESET,
      payload: {
        token,
        password,
      },
    })
  };

  
  useEffect(function registerSaga() {
    const task = store.run(resetRootSaga);

    return () => {

      if (task) {

        task.cancel();
      }
    }
  }, [store]);

  const messageSubmited = message ?
    <Alert severity="info">{message}</Alert>: null;

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{
        marginTop: (theme) => theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <Paper elevation={3} sx={{
          padding: (theme) => theme.spacing(3),
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Typography component="h1" variant="h5">
            Atualização de Senha
          </Typography>
          {messageSubmited}
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Senha"
              name="password"
              type="password"
              autoComplete="current-password"
              autoFocus
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                margin: (theme) => theme.spacing(3, 0, 2),
              }}
            >
              Atualizar
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}

export default Reset;
